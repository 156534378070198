/* eslint-disable  no-invalid-this, new-cap, no-throw-literal, no-undef */
/**
 * Used to integrate with QZ Tray software for label printing
 * https://qz.io
 */
const qz = require("qz-tray");
var RSVP = require("rsvp");
import { Printer } from "./printer";
import Swal from "sweetalert2";

const QZService = {
  init($container) {
    var self = this;
    $container.click(function() {
      self.printer = new Printer();
      self.name = self.printer.name;
      self.type = self.printer.type;
      self.isLabelPrinter = self.printer.isLabelPrinter;
      self.isMac = self.printer.isMac;
      self.url = $(this).data("url");

      self.connect().then(function() {
        return self.print(self.name, self.type, self.isLabelPrinter, self.isMac, [self.url]);
      }).then(function() {
        Swal("Label sent to printer");
      }).catch(function(error) {
        Swal("Error printing label: " + error);
      });
    });
  },

  print_from_urls(urls) {
    var self = this;

    return this.connect().then(function() {
      var printer = new Printer();
      var name = printer.name;
      var type = printer.type;
      var isLabelPrinter = printer.isLabelPrinter;
      var isMac = printer.isMac;
      return self.print(name, type, isLabelPrinter, isMac, urls);
    });
  },

  // Connects to QZ Tray
  // * handles the case when a connection is already active
  // * tries to connect once before launching the app
  // * after the app is launched will try to connect 3 more times
  connect(config) {
    this.initializeConnection();

    return new RSVP.Promise(function(resolve, reject) {
      if (qz.websocket.isActive()) {
        // If already active, resolve immediately
        resolve();
      } else {
        qz.websocket.connect().then(resolve, function() {
          // Allow time for the user to launch the app
          var configuration = Object.assign({ retries: 10, delay: 5 }, config);
          window.location.assign("qz:launch");
          qz.websocket.connect(configuration).then(resolve, reject);
        });
      }
    });
  },

  print(printer, type, isLabelPrinter, isMac, urls) {
    if (printer == undefined) {
      throw "Printer must be set first.";
    }

    var paperSize = isLabelPrinter ? { width: 4, height: 6 } : { width: 8.5, height: 11 },
      margins = isLabelPrinter ? 0 : { top: "1", left: "1" },
      scaleContent = !(isMac && !isLabelPrinter), // Don't scale for inkjet on mac
      data = [];
    var options = {};

    urls.forEach(function(url) {
      data.push({
        type: type,
        format: "file",
        data: url
      });
    });

    options = this.setOptions(type, paperSize, margins, scaleContent);
    var config = qz.configs.create(printer, options);

    return qz.print(config, data).catch(function() {
      let lang = $("html").attr("lang");

      if (lang == "fr") {
        throw "L'étiquette n'a pas pu être imprimée. Veuillez réessayer dans une minute. " +
        "Si le problème persiste, contactez le support.";
      } else {
        throw "Label could not be printed. Please try again in a minute. If the problem persist contact Support.";
      }
    });
  },

  initializeConnection() {
    qz.security.setCertificatePromise(function(resolve, reject) {
      var fingerprint = process.env.qz_certificate;
      $.ajax("/qz?v=" + fingerprint).then(resolve, reject);
    });

    qz.security.setSignaturePromise(function(toSign) {
      return function(resolve, reject) {
        $.post("/qz", { request: toSign }).then(resolve, reject);
      };
    });

    qz.websocket.setErrorCallbacks(function(error) {
      console.log(error);
    });
  },

  setOptions(type, paperSize, margins, scaleContent) {
    var options = {};
    if (type == "image") {
      options = {
        units: "in",
        size: paperSize,
        scaleContent: scaleContent,
        density: "300",
        duplex: false,
        margins: margins
      };
    }
    return options;
  }
};

export { QZService };
