/* eslint-disable no-undef, class-methods-use-this, no-invalid-this */
const qz = require("qz-tray");
import { QZService } from "./qz_service.js";
var RSVP = require("rsvp");

class PrinterQZConnection {
  constructor(printer, $container) {
    this.printer = printer;
    this.container = $container;
    this.printersContainer = this.container.find(".js-configure__printers");
    this.loadingContainer = this.container.find(".js-configure__loading");
    this.loadingMessage = this.loadingContainer.find(".js-configure__loading-message");
    this.qzMessage = this.loadingContainer.find(".js-configure__qz-message");
    this.instructions = this.container.find(".js-configure__instructions");
    this.findPrintersBtn = this.container.find(".js-configure__find-printers");
    this.isActive = false;
    this.bindEvents();

    this.textActive = this.container.find(".js-text-active").text();
    this.textInactive = this.container.find(".js-text-inactive").text();
    this.textConnectionClosed = this.container.find(".js-text-connection-closed").text();
    this.textWaiting = this.container.find(".js-text-waiting").text();
    this.textUsePrinter = this.container.find(".js-text-use-printer").text();
    this.textSelected = this.container.find(".js-text-selected").text();
    this.textError = this.container.find(".js-text-error").text();
    this.textConnectionToQZ = this.container.find(".js-text-connection-to-qz").text();
    this.textAConnectionError = this.container.find(".js-text-a-connection-error").text();
  }

  bindEvents() {
    var self = this;

    this.container.on("click", ".js-configure__find-printers, .js-configure__tab-link", function() {
      self.startQZConnection().then(function() {
        return self.findPrinters();
      }).catch(self.displayError);
    });

    this.container.on("click", ".js-test__tab-link", function() {
      self.startQZConnection();
    });
  }

  startQZConnection() {
    var self = this;
    qz.websocket.setClosedCallbacks(function(event) {
      self.setActiveState(false);
      self.updateQZConnectionState(self.textInactive);
      var message = "<strong>Connection closed</strong>";
      if (event.reason) {
        message += " " + event.reason;
      }
      self.displayMessage(message, "alert-warning");
      self.showLoading(false, false);
    });

    qz.websocket.setErrorCallbacks(self.handleConnectionError);

    self.updateQZConnectionState(self.textWaiting);
    self.showLoading(true, false);

    return new RSVP.Promise(function(resolve, reject) {
      QZService.connect().then(function() {
        self.setActiveState(true);
        self.updateQZConnectionState(self.textActive);
        resolve();
      }).catch(function(_event) {
        self.handleConnectionError; // eslint-disable-line no-unused-expressions
        reject();
      });
    });
  }

  findPrinters() {
    var self = this;
    self.showLoading(true, true);

    return qz.printers.find().then(function(data) {
      self.showLoading(false, false);
      var html = "";
      for (var i = 0; i < data.length; i += 1) {
        html += "<div class=\"my-2\">";
        // eslint-disable-next-line max-len
        html += "<button type=\"button\" class=\"btn btn-sm btn-outline-secondary js-set-printer mr-2\" data-name=\"" + data[i] + "\" style=\"width:125px\">" + self.textUsePrinter + "</button>";
        html += data[i];
        html += "</div>";
      }
      self.printersContainer.html(html);
      $(".js-printer-settings").trigger("update");
    });
  }

  displayMessage(msg, alertClass) {
    if (alertClass == undefined) {
      alertClass = "alert-info"; // eslint-disable-line no-param-reassign
    }

    var timeout = setTimeout(function() {
      $("#" + timeout).alert("close");
    }, 5000);

    var alertElement = $("<div/>").addClass("alert " + alertClass)
      .attr("id", timeout).attr("role", "alert");
    // eslint-disable-next-line max-len
    alertElement.html("<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" + msg);

    $(".js-notice").append(alertElement);
  }

  displayError(err) {
    this.displayMessage(err, "alert-danger");
  }

  updateQZConnectionState(text) {
    $(".js-qz-connection-status").html(text);
  }

  handleConnectionError(err) {
    this.setActiveState(false);
    this.showLoading(false, false);
    this.updateQZConnectionState(self.textError);

    if (err.target != undefined) {
      if (err.target.readyState >= 2) { // If CLOSING or CLOSED
        this.displayError(self.textConnectionToQZ);
      } else {
        this.displayError(self.textAConnectionError);
      }
    } else {
      this.displayError(err);
    }
  }

  showLoading(isVisible, isPrintersLoading) {
    if (isVisible) {
      this.loadingContainer.removeClass("d-none");
      this.instructions.addClass("d-none");
      this.printersContainer.addClass("d-none");
      this.findPrintersBtn.addClass("disabled");
      if (isPrintersLoading) {
        this.loadingMessage.removeClass("d-none");
        this.qzMessage.addClass("d-none");
      } else {
        this.loadingMessage.addClass("d-none");
        this.qzMessage.removeClass("d-none");
      }
    } else {
      this.loadingContainer.addClass("d-none");
      this.instructions.removeClass("d-none");
      this.printersContainer.removeClass("d-none");
      this.findPrintersBtn.removeClass("disabled");
      this.loadingMessage.addClass("d-none");
      this.qzMessage.addClass("d-none");
    }
  }

  setActiveState(status) {
    this.isActive = status;
    $("#printer-nav").trigger("update");
  }
}

export { PrinterQZConnection };
