/**
 * Used to setup label printer
 * Uses a permanent cookie to store printer name that will be used to
 * print labels directly
 * The printer should use 4 x 6 inch thermal labels
 */
import { Printer } from "./printer";
import { PrinterQZConnection } from "./printer_qz_connection";
import { PrinterStatus } from "./printer_status";
import { PrinterSettings } from "./printer_settings";
import { PrinterNav } from "./printer_nav";

class PrinterSetup {
  constructor($container) {
    this.printer = new Printer();
    this.status = new PrinterStatus(this.printer, $container);
    this.qzConnection = new PrinterQZConnection(this.printer, $container);

    new PrinterSettings(this.printer, this.status, $container); // eslint-disable-line no-new
    new PrinterNav(this.printer, this.qzConnection, $container); // eslint-disable-line no-new
  }
}

export { PrinterSetup };
