/* eslint-disable class-methods-use-this */
import Cookies from "js-cookie";

class Printer {
  constructor() {
    this.name = "";
    this.format = "";
    this.type = "";
    this.isLabelPrinter = false;
    this.isMac = false;
    this.cookie = Cookies.get("printer");
    this.setPrinterInfo(this.getPrinterInfo());
  }

  getPrinterInfo() {
    var info = null;
    if (this.cookie) {
      info = JSON.parse(atob(this.cookie));
    } else {
      info = {
        name: "",
        format: "unknown"
      };
    }
    return info;
  }

  setPrinterInfo(info) {
    this.name = info.name.replace(new RegExp("\\+", "g"), " ");
    this.format = info.format;
    this.type = (info.format == "zpl") ? "raw" : "image";
    this.isLabelPrinter = this._isLabelPrinter(this.name);
    this.isMac = this._isMac();

    $(".js-printer-status").trigger("update");
  }

  setCookie(info) {
    // Stringify and encode cookie into Base64
    var encodedValue = btoa(JSON.stringify(info));
    // Expire cookie in 20 years (similar to Rails' cookie.permanent)
    Cookies.set("printer", encodedValue, { expires: (365 * 20), path: "/" });
    this.cookie = Cookies.get("printer");
  }

  _isLabelPrinter(name) {
    return Boolean(name.match(/^(Dymo|LabelWriter)/i));
  }

  _isMac() {
    var platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

    return (macosPlatforms.indexOf(platform) !== -1);
  }
}

export { Printer };
