class PrinterStatus {
  constructor(printer) {
    this.printer = printer;
    this.statusContainer = $(".js-printer-setup").find(".js-printer-status");
    this.setStatus();
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    self.statusContainer.on("update", function(_e) {
      self.setStatus();
    });
  }

  setStatus() {
    var printer = this.printer;
    var format = $(".js-printer-format").data(printer.format + "-value");
    var name = this.printer.name || "Unknown";

    this.setField("name", name);
    this.setField("format", format);
  }

  setField(field, info) {
    this.statusContainer.find(".js-printer-" + field).html(info);
  }
}

export { PrinterStatus };
