/* eslint-disable no-invalid-this */
class PrinterSettings {
  constructor(printer, status, $container) {
    this.printer = printer;
    this.status = status;
    this.container = $container;
    this.settingsContainer = this.container.find(".js-printer-settings");
    this.setActiveFormatDropdown(this.printer.format);
    this.bindEvents($container);
  }

  bindEvents() {
    var self = this;

    self.container.on("click", ".js-set-printer", function() {
      var name = $(this).data("name");
      var format = self.printerFormat(name);
      self.updatePrinter(name, format);
    });

    self.container.on("click", ".js-format-btn", function(e) {
      e.preventDefault();
      var name = self.printer.name;
      var format = $(e.target).data("format-value");

      if (format == "reset") {
        format = self.printerFormat(name);
      }

      self.updatePrinter(name, format);
    });

    self.settingsContainer.on("update", function(_e) {
      self.setActivePrinterButton(self.printer.name);
    });
  }

  updatePrinter(name, format) {
    var info = {
      name: name,
      format: format
    };

    this.printer.setPrinterInfo(info);
    this.printer.setCookie(info);
    this.setLabelUrl(format);
    this.setActiveFormatDropdown(format);
    this.setActivePrinterButton(name);
  }

  setActiveFormatDropdown(format) {
    $(".js-format-btn").removeClass("active");

    this.settingsContainer
      .find("a[data-format-value='" + format + "']")
      .addClass("active");
  }

  setActivePrinterButton(name) {
    this.container.find(".js-set-printer")
      .html($(".js-text-use-printer").text())
      .removeClass("btn-secondary")
      .addClass("btn-outline-secondary");

    this.container
      .find(".js-configure__printers")
      .find("[data-name='" + name + "']")
      .html($(".js-text-selected").text())
      .removeClass("btn-outline-secondary")
      .addClass("btn-secondary");

    $("#printer-nav").trigger("update");
  }

  setLabelUrl(format) {
    var url = this.container.find(".js-print-label").data(format + "-print-url");
    this.container.find(".js-print-label").data("url", url);
  }

  printerFormat() {
    return this.printer.name.match(/^(Zebra|raw|ZP)/) ? "zpl" : "png";
  }
}

export { PrinterSettings };
